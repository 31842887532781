<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <FiltrosLeads @filtrosModificados="updateFilters" :loading="loading" />
      </v-col>
      <v-col cols="6">
        <LeadsAgenda />
      </v-col>
      <v-col cols="12">
        <ResultadosLeads
          :loading="loading"
          :leads="leads"
          :totalRegistros="totalRegistros"
          @paginacionModificada="paginacionModificada"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logoSegurify from "@/assets/intermx-1.svg";
import FiltrosLeads from "./FiltrosLeads.vue";
import ResultadosLeads from "./ResultadosLeads.vue";
import LeadsAgenda from "./LeadsAgenda.vue";

export default {
  components: {
    FiltrosLeads,
    ResultadosLeads,
    LeadsAgenda,
  },
  data() {
    return {
      logoSegurify,
      loading: false,
      leads: [],
      registrosPagina: 10,
      pagina: 0,
      totalRegistros: 0,
      nuevosFiltros: {},
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },
  methods: {
    async realizarBusqueda() {
      try {
        this.loading = true;
        const busquedaCompleta =
          !!this.nuevosFiltros.nombre &&
          !!this.nuevosFiltros.apellido_paterno &&
          !!this.nuevosFiltros.apellido_materno &&
          !!this.nuevosFiltros.rfc &&
          !!this.nuevosFiltros.fecha_nacimiento;
        if (busquedaCompleta && !this.fromComplete) {
          this.updateFilters(this.nuevosFiltros);
          return;
        }

        this.leads = [];
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        var filtros = {
          ...this.nuevosFiltros,
          ...{
            page: this.pagina,
            size: this.registrosPagina,
          },
        };
        const response = await this.$axios.post(
          "/v1/bradescard/list/filtros",
          filtros,
          config
        );
        this.loading = false;
        if (!response.data.leads.length || response.data.error) return;
        response.data.leads.forEach((lead) => {
          this.leads.push({
            id: lead.id,
            nombre: `${lead.nombre} ${lead.apellido_Paterno ||
              ""} ${lead.apellido_Materno || ""}`,
            uuid: lead.uuid,
            fourUD: lead.fourUD || "No disponible",
            status: lead.status || "No disponible",
            producto: lead.producto || "No disponible",
            productoAseguradora: lead.productoAseguradora || "No disponible",
            ultimaDisposicion: lead.ultimaDisposicion || "No disponible",
            operador: lead.nombreAgente || "No disponible",
            isInbound: true,
            etiqueta: lead.etiqueta || "No disponible",
          });
        });
        this.totalRegistros = response.data.numRegisters;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$toast.error(
            "No se encontraron resultados. Intente nuevamente."
          );
        } else {
          this.$toast.error(
            "Se ha producido un error inesperado. Intente nuevamente más tarde."
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async realizarBusquedaCompleta(filtros) {
      await this.realizarBusqueda(filtros, true);
      if (!this.leads.length) await this.realizarBusqueda({ rfc: filtros.rfc });
      if (!this.leads.length)
        await this.realizarBusqueda({ rfc: filtros.rfc.substring(0, 10) });
      if (!this.leads.length)
        await this.realizarBusqueda({
          nombre: filtros.nombre,
          apellido_paterno: filtros.apellido_paterno,
          apellido_materno: filtros.apellido_materno,
        });
      if (!this.leads.length)
        await this.realizarBusqueda({
          nombre: `${filtros.nombre} ${filtros.apellido_paterno} ${filtros.apellido_materno}`,
        });
      if (!this.leads.length)
        await this.realizarBusqueda({
          nombre: `${filtros.apellido_paterno} ${filtros.apellido_materno} ${filtros.nombre}`,
        });
    },
    paginacionModificada(e) {
      this.pagina = (parseInt(e.pagina)) - 1;
      this.registrosPagina = e.registrosPagina;
      this.realizarBusqueda();
    },
    updateFilters(e, fromComplete = false) {
      (this.nuevosFiltros = e), (this.fromComplete = fromComplete);
      this.realizarBusqueda();
    },
  },
};
</script>
